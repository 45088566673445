import dynamic from 'next/dynamic';
import { cloudinaryApply, cloudinaryLoader } from "utils/cloudinaryUtils";
import { stringMinify } from 'utils/StringUtils';
import useResize from 'components/Hooks/useResize';
import { DeviceEnum } from 'models/enums';

const Image = dynamic(() => import('next/image'));
const BannerButtons = dynamic(() => import('components/Banner/BannerButtons'));

const BannerImage = ({ settings }: any) => {

    const { whichDevice } = useResize();
    const isMobileDevice = whichDevice === DeviceEnum.MOBILE;

    const sliderList = settings.sliderItems;
    let sliderHeight = settings.sliderHeight;

    if (isMobileDevice) {
        sliderHeight = 610;
    }
    const sliderHeightProperty = `${sliderHeight > 0 ? sliderHeight : 600}px`;
    const {
        overrideImageOnMobile,
        mobileImageUrl,
        // sliderDelay,
        // mobileTextPositionX,
        // mobileTextPositionY,
        // mobileFontSize,
    } = settings;
    const overrideImageMobile = (isMobileDevice && overrideImageOnMobile && mobileImageUrl != "");
    const image = settings.sliderItems[0]; //TODO: read all images and implmente a slider
    const customStyle = `
        .mobile-image, .ax-mobile,
        .visibleinmobile {
            display: none;
        }
        .swiper-slide {
            background-position: center;
            background-size: cover;
            height: ${sliderHeightProperty};
        }
        ${overrideImageMobile ? `
            @media screen and (max-width: 448px) {
                #ax-rev-slider-wrapper {
                    display: none !important;
                }
                .tp-fullwidth-forcer {
                    display: none !important;
                }
                .mobile-image, .ax-mobile {
                    width: 100%;
                    display: block;
                    content: url(${mobileImageUrl}) !important;
                }
                .visibleinmobile {
                    display: block;
                    min-height: 480px;
                }
            }
        `: ''}
    `;

    const imageWidth = isMobileDevice ? 640 : 1920;
    const imageHeight = isMobileDevice ? 915 : 660;

    return (
        <section className="slider container-full p-t-50">
            <style jsx>{`${stringMinify(customStyle)}`}</style>
            {sliderList.length === 1 && (
                <div className="rev_slider_wrapper fullwidthbanner-container">
                    <div className="fullwidthabanner">
                        <Image
                            className='img-responsive mainimagebanner'
                            loader={cloudinaryLoader}
                            src={overrideImageMobile ? mobileImageUrl : image.value.imageUrl}
                            width={overrideImageMobile ? 640 : imageWidth}
                            height={overrideImageMobile ? 915 : imageHeight}
                            alt={image.title || 'Banner Image'}
                            priority
                            placeholder='blur'
                            blurDataURL={`${cloudinaryApply(overrideImageMobile ? mobileImageUrl : image.value.imageUrl)}?tx=w_10,q_1`}
                        />
                    </div>
                    <BannerButtons settings={settings} />
                </div>
            )}
            {sliderList.length > 1 && <span>Implement Slider Banner Here</span>}
        </section>
    );
};

export default BannerImage;